import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { deploy } from 'config';
import UpSleepSensorTable from '../UpSleepSensorTable';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 300
  },
  labelText: {
    color: '#aaaaaa'
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const STRING_SEARCH = '검색';
const STRING_TITLE = '상세 조회할 세션을 선택하세요.';

const UpSleepSensorSessionList = props => {
  const {
    className,
    sessionIdList,
    searchSessionId,
    setSearchSessionId,
    getSensorData,
    ...rest
  } = props;

  const classes = useStyles();
  const handleSessionIdChange = event => {
    setSearchSessionId(event.target.value);
  };

  const sessionSuccessList = useMemo(() => {
    return sessionIdList.filter(item => item.eventType == 'SESS#COMP');
  }, [sessionIdList]);

  const sessionFailList = useMemo(() => {
    return sessionIdList.filter(item => item.eventType == 'SESS#FAIL');
  }, [sessionIdList]);

  const columns = useMemo(() => {
    return [
      'eventType',
      'session_id',
      'userId',
      'user_id',
      'session',
      'side',
      'thingName',
      'api_key'
    ];
  }, []);

  const rows = useMemo(() => {
    let result = [];
    sessionIdList.reduce((res, current) => {
      let sessionInfom = [];
      let isSession = true;
      let aiSleepApikey =
        deploy == 'prod'
          ? 'RqSxny35gjBTYAMQs6Fyz2ZARJIjgyR4VrjR7BdG'
          : '8FZOIRp1qw0ROF6d5zLTeDwK7TQ9hNa083OBfuZc';
      columns.forEach(key => {
        if (current.hasOwnProperty(key)) {
          if (key == 'eventType' && current[key] == 'SESS#FAIL') {
            isSession = false;
          }
          sessionInfom.push(
            typeof current[key] == 'object' ? (
              key == 'session' ? (
                current[key].hasOwnProperty('start_time') ? (
                  `${current[key].start_time} ~ ${current[key].end_time}`
                ) : (
                  'X'
                )
              ) : (
                JSON.stringify(current[key])
              )
            ) : typeof current[key] == 'boolean' ? (
              current[key] ? (
                'O'
              ) : (
                'X'
              )
            ) : key == 'session_id' && isSession ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => getSensorData(current[key])}>
                {current[key]}
              </Button>
            ) : key == 'api_key' ? (
              current[key] == aiSleepApikey ? (
                'AI수면'
              ) : (
                '수면센싱'
              )
            ) : current[key] ? (
              current[key]
            ) : (
              'X'
            )
          );
        } else {
          sessionInfom.push('');
        }
      });
      result.push(sessionInfom);
    }, []);
    return result;
  }, [columns, sessionIdList]);

  // const sessionDetail = useMemo(() => {
  //   if (searchSessionId && sessionSuccessList.length > 0) {
  //     const sessionInform = sessionSuccessList.filter(
  //       item => item.session.id === searchSessionId
  //     )[0];
  //     let endDate = new Date(sessionInform?.session?.end_time);
  //     let startDate = new Date(sessionInform?.session?.start_time);
  //     let state = !!sessionInform?.state?.sleepIndex;
  //     if (
  //       !state &&
  //       (endDate.getTime() - startDate.getTime()) / (1000 * 60) > 20
  //     ) {
  //       state = true;
  //     }
  //     return {
  //       thingName: sessionInform?.thingName,
  //       state,
  //       side: sessionInform?.side,
  //       user_id: sessionInform?.user_id
  //     };
  //   }
  //   return {
  //     thingName: '',
  //     state: false,
  //     side: '',
  //     user_id: ''
  //   };
  // }, [searchSessionId, sessionIdList, sessionSuccessList]);

  return (
    <Card style={{ marginTop: 10 }} className={clsx(classes.root, className)}>
      <CardHeader title={STRING_TITLE} />
      <Divider />
      <Grid container spacing={1}>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <p>{`전체 세션 개수 : ${sessionIdList.length}`}</p>
          <p>{`성공 세션 개수 : ${sessionSuccessList.length}`}</p>
          <p>{`실패 세션 개수 : ${sessionFailList.length}`}</p>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        {rows.length > 0 && (
          <UpSleepSensorTable columns={columns} rows={rows} />
        )}
      </CardContent>
      <Divider />
    </Card>
  );
};

UpSleepSensorSessionList.propTypes = {
  className: PropTypes.string
};

export default UpSleepSensorSessionList;
